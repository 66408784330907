import React from "react";

import PropTypes from "prop-types";

export const TwitterIcon = ({ className }) => (
  <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M22.0773 2.15949C21.2776 2.51322 20.4296 2.74553 19.5613 2.84873C20.4765 2.30136 21.1615 1.43989 21.4886 0.42487C20.6297 0.936039 19.688 1.29428 18.7065 1.48806C18.0472 0.782666 17.1734 0.314846 16.2208 0.157328C15.2682 -0.000190064 14.2903 0.16142 13.439 0.617033C12.5878 1.07265 11.9109 1.79673 11.5136 2.67673C11.1163 3.55673 11.0209 4.54332 11.2422 5.48314C9.50035 5.39584 7.79634 4.94319 6.24079 4.15459C4.68523 3.36598 3.31292 2.25905 2.21293 0.905662C1.82358 1.57442 1.61897 2.33466 1.62006 3.10851C1.62006 4.62735 2.3931 5.96917 3.56837 6.75478C2.87285 6.73288 2.19265 6.54505 1.58445 6.20695V6.26142C1.58466 7.27296 1.93469 8.25332 2.5752 9.03625C3.21571 9.81918 4.10728 10.3565 5.09873 10.5571C4.45308 10.7321 3.77609 10.7579 3.119 10.6325C3.39854 11.5032 3.94337 12.2647 4.67722 12.8104C5.41107 13.356 6.29718 13.6585 7.2115 13.6755C6.30279 14.3891 5.26233 14.9167 4.14959 15.228C3.03686 15.5393 1.87368 15.6282 0.726562 15.4897C2.72902 16.7775 5.06008 17.4612 7.44089 17.459C15.4991 17.459 19.9059 10.7834 19.9059 4.99397C19.9059 4.80542 19.9006 4.61478 19.8923 4.42833C20.75 3.8084 21.4903 3.04044 22.0783 2.16054L22.0773 2.15949Z" fill="#A5A5A5"/>
  </svg>
);

TwitterIcon.propTypes = {
  className: PropTypes.string,
};

TwitterIcon.defaultProps = {
  className: '',
}
