import React from "react";

import PropTypes from "prop-types";

export const ErrorImage = ({ className }) => (
  <>
  <svg viewBox="0 0 484 353" fill="none" xmlns="http://www.w3.org/2000/svg"className={className}>
    <path opacity="0.05" d="M435.621 60.5478C357.442 8.19811 235.553 75.6549 159.944 80.2976C111.62 83.2637 44.7344 64.4996 44.7344 64.4996L51.9102 121.971L63.2037 219.273L62.2273 217.413L72.9772 303.514C84.7405 319.984 120.095 328.017 120.095 328.017L119.883 327.621C160.386 336.823 219.479 333.332 275.154 310.432C372.871 270.232 442.824 318.335 442.824 318.335C428.426 266.98 435.621 60.557 435.621 60.557V60.5478Z" fill="black"/>
    <path d="M413.08 47.016C413.08 47.016 405.877 253.43 420.284 304.794C420.284 304.794 350.34 256.7 252.614 296.891C167.618 331.849 74.6634 321.578 52.0304 289.973L22.2031 50.9586C22.2031 50.9586 89.0888 69.7319 137.413 66.7565C213.022 62.1139 334.91 -5.34291 413.089 47.0068L413.08 47.016Z" fill="#FFBDD4"/>
    <path d="M395.918 57.5633C395.918 57.5633 385.583 235.32 398.617 277.196C398.617 277.196 333.15 241.464 244.709 274.23C167.783 302.731 82.7318 295.555 62.2543 269.79L35.8262 67.475C35.8262 67.475 96.3558 82.7755 140.093 80.362C208.517 76.5761 325.163 14.895 395.918 57.5725V57.5633Z" fill="#FFCFE4"/>
    <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
    <path d="M39.6869 203.881L97.5452 314.485C97.5452 314.485 62.191 306.443 50.4277 289.982L39.6777 203.881H39.6869Z" fill="black"/>
    </g>
    <path d="M29.3691 108.43L108.036 267.063C108.036 267.063 50.3072 242.929 50.4361 289.973L29.3691 108.43Z" fill="#FFBDD4"/>
    <path d="M369.318 96.9523L365.219 97.2287C365.118 95.635 364.924 94.0598 364.722 92.4386L364.408 89.9699C364.289 89.1961 364.224 88.192 364.233 87.1511L368.332 87.4919L368.369 88.4039C368.388 88.7355 368.452 89.1685 368.498 89.5461L368.802 91.9596C369.005 93.5901 369.217 95.2666 369.318 96.9523Z" fill="#EE1A64"/>
    <path d="M367.881 112.161L363.947 111.018C364.546 109.6 364.629 108.015 365.016 106.523C365.126 104.966 365.393 103.437 365.357 101.853L369.474 101.926C369.511 103.631 369.235 105.353 369.097 107.067C368.682 108.762 368.562 110.512 367.899 112.161H367.881Z" fill="#EE1A64"/>
    <path d="M358.965 125.177L356.34 122.137C357.436 121.059 358.809 120.221 359.638 118.922L361.056 117.116C361.544 116.527 361.802 115.79 362.189 115.136L365.846 116.941C365.395 117.715 365.072 118.554 364.501 119.263L362.834 121.391C361.821 122.911 360.264 123.924 358.956 125.167L358.965 125.177Z" fill="#EE1A64"/>
    <path d="M344.555 131.855L343.496 128.042L345.882 127.47C346.674 127.268 347.383 126.899 348.139 126.623L350.377 125.729L351.492 125.278L352.496 124.661L354.541 128.088L353.38 128.778L352.137 129.285L349.64 130.28C348.802 130.584 347.991 130.998 347.135 131.22L344.555 131.846V131.855Z" fill="#EE1A64"/>
    <path d="M329.026 133.928L328.879 129.985C332.241 129.958 335.502 129.405 338.8 129.055L339.491 132.951C336.009 133.338 332.508 133.9 329.036 133.937L329.026 133.928Z" fill="#EE1A64"/>
    <path d="M313.275 133.559L314.15 129.663C317.421 129.948 320.709 130.142 323.998 130.105L323.878 134.057C320.331 134.084 316.794 133.882 313.275 133.559Z" fill="#EE1A64"/>
    <path d="M297.955 130.04L299.908 126.457C302.957 127.645 306.163 128.382 309.387 129.009L308.143 132.831C304.68 132.141 301.234 131.33 297.964 130.04H297.955Z" fill="#EE1A64"/>
    <path d="M284.781 121.879L287.646 118.885C289.931 121.16 292.639 123.012 295.531 124.513L293.256 127.931C290.152 126.319 287.241 124.32 284.781 121.879Z" fill="#EE1A64"/>
    <path d="M276.105 109.314L279.643 107.168C280.96 110.051 282.498 112.87 284.497 115.385L281.384 118.121C279.228 115.431 277.552 112.409 276.115 109.314H276.105Z" fill="#EE1A64"/>
    <path d="M270.947 94.9901L274.871 93.811C275.857 96.8693 276.769 99.8723 277.874 102.857L274.181 104.681C272.974 101.494 271.97 98.1958 270.947 94.9901Z" fill="#EE1A64"/>
    <path d="M264.902 82.7018L267.887 79.9844C270.577 82.7294 272.06 86.018 273.266 89.1684L269.398 90.5225C268.283 87.5563 266.91 84.7284 264.902 82.711V82.7018Z" fill="#EE1A64"/>
    <path d="M252.938 79.9291L251.842 76.1247C255.554 75.1575 259.561 75.268 263.2 76.7787L261.551 80.3989C258.944 79.3119 255.757 79.1738 252.938 79.9383V79.9291Z" fill="#EE1A64"/>
    <path d="M241.967 87.7223L238.715 85.3088C240.916 82.573 243.551 80.0674 246.757 78.2251L248.866 81.615C246.222 83.1349 243.919 85.2996 241.967 87.7223Z" fill="#EE1A64"/>
    <path d="M235.536 100.066L231.594 98.9513C232.653 95.6904 233.97 92.4939 235.767 89.5186L239.332 91.4898C237.71 94.1796 236.504 97.0997 235.536 100.075V100.066Z" fill="#EE1A64"/>
    <path d="M233.749 113.874L229.64 114.022C229.511 110.613 229.733 107.177 230.414 103.824L234.458 104.534C233.832 107.61 233.629 110.751 233.758 113.874H233.749Z" fill="#EE1A64"/>
    <path d="M236.687 127.452L232.864 128.907C231.565 125.72 230.598 122.404 230.119 119.014L234.191 118.434C234.624 121.529 235.508 124.55 236.687 127.452Z" fill="#EE1A64"/>
    <path d="M244.213 139.307L241.099 141.896C239.994 140.56 238.778 139.307 237.839 137.843L236.346 135.715L235.057 133.467L238.686 131.606L239.856 133.661L241.219 135.604C242.067 136.94 243.191 138.091 244.204 139.317L244.213 139.307Z" fill="#EE1A64"/>
    <path d="M255.286 148.436L253.057 151.752C251.62 150.776 250.146 149.836 248.736 148.823C247.392 147.718 245.991 146.677 244.711 145.507L247.53 142.633C248.709 143.711 250.017 144.678 251.26 145.7C252.568 146.649 253.95 147.515 255.286 148.436Z" fill="#EE1A64"/>
    <path d="M268.522 154.709L267.067 158.403C263.778 157.27 260.61 155.815 257.496 154.267L259.458 150.794C262.397 152.25 265.381 153.641 268.522 154.709Z" fill="#EE1A64"/>
    <path d="M282.921 158.707L281.908 162.53C278.574 161.802 275.239 161.074 271.904 160.006L273.139 156.238C276.28 157.252 279.596 157.979 282.912 158.698L282.921 158.707Z" fill="#EE1A64"/>
    <path d="M298.037 162.972L296.029 166.473C294.537 165.819 292.934 165.432 291.387 164.888C289.839 164.335 288.245 163.967 286.643 163.608L287.941 159.849C289.664 160.236 291.387 160.642 293.045 161.24C294.703 161.821 296.435 162.254 298.037 162.963V162.972Z" fill="#EE1A64"/>
    <path d="M311.559 170.7L308.63 173.611C307.35 172.616 306.005 171.714 304.697 170.765L300.432 168.388L302.762 165.054L307.34 167.633C308.75 168.655 310.196 169.641 311.569 170.71L311.559 170.7Z" fill="#EE1A64"/>
    <path d="M321.158 182.611L317.538 184.684C316.175 181.81 314.194 179.24 312.066 176.845L315.254 174.182C317.547 176.752 319.666 179.516 321.149 182.602L321.158 182.611Z" fill="#EE1A64"/>
    <path d="M325.754 197.064L321.747 198.133C321.553 196.567 321.12 195.038 320.825 193.481C320.365 191.97 320.006 190.422 319.416 188.949L323.193 187.189C323.828 188.774 324.234 190.432 324.731 192.062C325.063 193.729 325.533 195.378 325.744 197.064H325.754Z" fill="#EE1A64"/>
    <path d="M326.952 212.144L322.844 212.236C322.752 209.012 322.66 205.87 322.328 202.729L326.39 202.02C326.75 205.401 326.851 208.818 326.952 212.153V212.144Z" fill="#EE1A64"/>
    <path d="M327.541 226.597L323.451 227.02C323.083 223.649 323 220.323 322.936 217.044L327.044 216.961C327.099 220.231 327.201 223.455 327.541 226.597Z" fill="#EE1A64"/>
    <path d="M332.507 238.305L330.001 241.437C326.722 239.042 325.101 235.357 324.271 232.004L328.279 231.101C329.006 234.04 330.305 236.702 332.507 238.305Z" fill="#EE1A64"/>
    <path d="M345.616 240.221L346.113 244.145C342.622 244.661 338.9 244.642 335.197 243.804L336.266 239.99C339.149 240.635 342.373 240.718 345.616 240.23V240.221Z" fill="#EE1A64"/>
    <path d="M359.507 236.536L361.414 240.037C358.144 241.538 354.634 242.726 351.217 243.297L350.369 239.429C353.685 238.876 356.633 237.826 359.507 236.536Z" fill="#EE1A64"/>
    <path d="M368.414 227.067L372.319 228.31C371.159 231.811 368.754 234.841 365.908 237.107L363.273 234.077C365.622 232.198 367.511 229.821 368.423 227.067H368.414Z" fill="#EE1A64"/>
    <path d="M367.391 214.235L371.167 212.669C372.586 215.902 373.424 219.458 373.277 223.069L369.168 222.894C369.279 219.983 368.616 217.017 367.391 214.225V214.235Z" fill="#EE1A64"/>
    <path d="M358.9 203.245L361.673 200.325C364.215 202.711 366.721 205.198 368.609 208.173L365.145 210.301C363.487 207.667 361.203 205.41 358.891 203.236L358.9 203.245Z" fill="#EE1A64"/>
    <path d="M346.777 195.406L348.628 191.878C351.779 193.37 354.782 195.167 357.637 197.166L355.178 200.334C352.534 198.483 349.734 196.806 346.768 195.406H346.777Z" fill="#EE1A64"/>
    <path d="M332.949 190.358L334.036 186.545C337.389 187.475 340.724 188.498 343.948 189.815L342.364 193.463C339.314 192.21 336.146 191.243 332.949 190.358Z" fill="#EE1A64"/>
    <path d="M318.303 187.724L318.763 183.8C322.227 184.104 325.644 184.758 329.062 185.366L328.196 189.225C324.917 188.645 321.637 188.009 318.303 187.724Z" fill="#EE1A64"/>
    <path d="M303.353 187.033L303.307 183.081H308.502L313.697 183.348L313.412 187.291L308.391 187.024L303.353 187.033Z" fill="#EE1A64"/>
    <path d="M288.366 187.982L287.896 184.057L293.055 183.523L298.232 183.238L298.425 187.18L293.387 187.456L288.366 187.982Z" fill="#EE1A64"/>
    <path d="M273.196 190.34L272.975 186.342C276.171 185.614 279.404 185.246 282.868 184.656L283.457 188.562C280.206 189.115 276.641 189.529 273.186 190.331L273.196 190.34Z" fill="#EE1A64"/>
    <path d="M257.84 193.803L258.715 189.658C261.902 188.884 265.062 187.991 268.267 187.309L268.12 191.39C264.656 192.118 261.276 193.011 257.849 193.803H257.84Z" fill="#EE1A64"/>
    <path d="M242.381 194.476L244.232 190.533C245.006 190.819 245.826 190.892 246.646 190.957C247.466 191.021 248.276 191.15 249.096 191.169C250.727 190.994 252.394 191.086 253.997 190.699L252.799 194.817C251.086 195.185 249.317 195.065 247.576 195.222C246.701 195.185 245.835 195.047 244.96 194.964C244.085 194.881 243.21 194.789 242.381 194.476Z" fill="#EE1A64"/>
    <path d="M230.029 185.697L232.746 182.28C234.561 184.905 236.974 187.171 239.793 188.774L237.628 192.578C234.607 190.865 232.009 188.47 230.02 185.697H230.029Z" fill="#EE1A64"/>
    <path d="M222.871 172.322L226.224 169.697C227.394 172.644 228.656 175.564 230.222 178.346L227.265 181.533C225.552 178.586 224.152 175.481 222.871 172.331V172.322Z" fill="#EE1A64"/>
    <path d="M217.857 157.933L221.634 156.293C222.592 159.315 223.578 162.327 224.61 165.321L221.1 167.642C219.958 164.427 218.898 161.185 217.857 157.933Z" fill="#EE1A64"/>
    <path d="M213.197 143.793L217.094 142.513C218.19 145.617 219.259 148.851 220.226 151.863L216.338 153.152C215.298 149.901 214.293 146.888 213.206 143.784L213.197 143.793Z" fill="#EE1A64"/>
    <path d="M207.66 130.418L211.372 128.723L213.5 133.301L215.398 137.944L211.547 139.317L209.705 134.821L207.66 130.418Z" fill="#EE1A64"/>
    <path d="M199.979 118.36L203.193 115.891L206.307 120.009L209.034 124.338L205.432 126.254L202.871 122.183L199.979 118.36Z" fill="#EE1A64"/>
    <path d="M189.184 109.204L191.293 105.814C192.85 106.661 194.241 107.767 195.724 108.734C197.124 109.793 198.386 111.018 199.722 112.151L196.802 114.933C195.586 113.911 194.443 112.787 193.172 111.829C191.837 110.963 190.584 109.968 189.184 109.204Z" fill="#EE1A64"/>
    <path d="M175.66 104.497L176.351 100.6L181.556 101.761C183.26 102.258 184.918 102.931 186.604 103.511L184.973 107.131C183.444 106.615 181.961 106.007 180.413 105.547L175.66 104.487V104.497Z" fill="#EE1A64"/>
    <path d="M161.004 104.137L160.654 100.204C162.386 99.9736 164.136 99.9183 165.905 99.946C167.664 99.992 169.424 99.7986 171.183 100.056L170.888 103.999C169.267 103.75 167.6 103.953 165.96 103.898C164.311 103.87 162.662 103.916 161.014 104.137H161.004Z" fill="#EE1A64"/>
    <path d="M160.992 103.152L161.231 99.2092C164.547 99.4027 167.854 99.7067 171.17 100.241L170.48 104.137C167.375 103.631 164.179 103.336 160.982 103.152H160.992Z" fill="#EE1A64"/>
    <path d="M146.723 103.262L146.4 99.3197C149.717 99.071 153.042 98.988 156.358 99.0341L156.294 102.986C153.097 102.949 149.892 103.023 146.714 103.262H146.723Z" fill="#EE1A64"/>
    <path d="M132.778 105.381L131.627 101.586C134.906 100.83 138.25 100.186 141.548 99.8171L142.073 103.732C138.895 104.091 135.837 104.69 132.778 105.381Z" fill="#EE1A64"/>
    <path d="M119.632 109.959L117.477 106.542C120.507 105.114 123.63 103.852 126.854 102.894L128.346 106.588C125.343 107.472 122.442 108.633 119.632 109.95V109.959Z" fill="#EE1A64"/>
    <path d="M108.07 117.485L105.049 114.685C107.591 112.557 110.235 110.521 113.146 108.872L115.605 112.105C112.888 113.625 110.438 115.514 108.07 117.485Z" fill="#EE1A64"/>
    <path d="M99.32 127.922L95.6445 125.923L96.9434 123.841L98.4172 121.87C99.3568 120.516 100.499 119.318 101.586 118.084L104.847 120.635C103.834 121.787 102.765 122.892 101.899 124.145L100.527 125.978L99.32 127.913V127.922Z" fill="#EE1A64"/>
    <path d="M93.8952 140.275L89.8145 139.224C90.7264 136.111 91.8779 133.053 93.3333 130.133L97.1653 131.828C95.8112 134.536 94.7427 137.382 93.8952 140.275Z" fill="#EE1A64"/>
    <path d="M92.6331 153.576L88.4326 153.539C88.0089 150.324 88.1931 147.054 88.6998 143.858L92.8634 144.586C92.4028 147.561 92.2278 150.592 92.6424 153.585L92.6331 153.576Z" fill="#EE1A64"/>
    <path d="M96.76 166.343L92.7621 167.311C91.2146 164.427 90.0631 161.36 89.2617 158.219L93.4346 157.924C94.1992 160.844 95.2953 163.681 96.76 166.343Z" fill="#EE1A64"/>
    <path d="M104.783 177.314L101.31 179.203C99.053 176.78 96.9712 174.21 95.1934 171.447L99.053 170.166C100.73 172.709 102.682 175.085 104.783 177.314Z" fill="#EE1A64"/>
    <path d="M115.212 186.268L112.522 188.949C109.823 186.978 107.17 184.942 104.766 182.639L108.008 180.474C110.247 182.584 112.706 184.463 115.212 186.268Z" fill="#EE1A64"/>
    <path d="M127.167 193.241L125.454 196.53C122.396 195.102 119.476 193.435 116.602 191.694L118.987 188.792C121.65 190.386 124.339 191.933 127.167 193.241Z" fill="#EE1A64"/>
    <path d="M140.174 198.188L139.557 201.863C136.314 200.905 133.09 199.865 129.977 198.547L131.331 195.111C134.195 196.327 137.171 197.304 140.165 198.197L140.174 198.188Z" fill="#EE1A64"/>
    <path d="M153.929 200.601L154.454 204.387C151.073 204.176 147.711 203.733 144.395 203.024L144.634 199.284C147.692 199.957 150.806 200.38 153.929 200.601Z" fill="#EE1A64"/>
    <path d="M167.809 199.349L169.412 202.932C166.16 203.798 162.798 204.36 159.418 204.434L158.533 200.675C161.674 200.629 164.788 200.141 167.809 199.349Z" fill="#EE1A64"/>
    <path d="M180.199 193.205L182.759 196.272C180.005 198.151 177.076 199.8 174.036 201.228L172.102 197.783C174.92 196.466 177.638 194.946 180.199 193.205Z" fill="#EE1A64"/>
    <path d="M189.992 183.883L193.455 186.01C191.539 188.866 189.227 191.224 186.685 193.361L183.848 190.515C186.206 188.544 188.389 186.287 189.992 183.883Z" fill="#EE1A64"/>
    <path d="M193.032 171.953L197.104 171.41C197.647 174.846 197.122 178.402 195.814 181.58L191.973 180.161C193.05 177.536 193.465 174.708 193.023 171.953H193.032Z" fill="#EE1A64"/>
    <path d="M186.969 160.945L189.88 158.154C192.321 160.531 194.439 163.322 195.757 166.528L191.943 167.992C190.874 165.367 189.087 163 186.969 160.936V160.945Z" fill="#EE1A64"/>
    <path d="M175.492 154.028L176.929 150.324C180.144 151.467 183.23 153.005 186.021 154.976L183.598 158.164C181.139 156.423 178.385 155.05 175.501 154.028H175.492Z" fill="#EE1A64"/>
    <path d="M161.922 151.752L161.968 147.801C165.339 147.957 168.766 148.095 172.11 148.924L171.161 152.766C168.177 152.019 165.045 151.9 161.931 151.743L161.922 151.752Z" fill="#EE1A64"/>
    <path d="M148.133 153.539L147 149.744C148.649 149.376 150.289 148.934 151.947 148.62L156.985 147.966L157.326 151.9L152.684 152.508C151.154 152.793 149.644 153.208 148.124 153.539H148.133Z" fill="#EE1A64"/>
    <path d="M135.597 159.149L133.285 155.879C136.076 153.982 139.153 152.526 142.294 151.292L143.814 154.967C140.94 156.1 138.13 157.427 135.606 159.149H135.597Z" fill="#EE1A64"/>
    <path d="M125.777 168.352L122.424 166.067C123.29 164.639 124.543 163.497 125.648 162.244C126.228 161.636 126.762 160.992 127.38 160.43L129.323 158.818L132.004 161.811L130.244 163.258C129.683 163.773 129.213 164.354 128.688 164.907C127.693 166.04 126.541 167.062 125.777 168.361V168.352Z" fill="#EE1A64"/>
    <path d="M119.9 180.308L115.939 179.231C116.474 177.683 116.897 176.089 117.533 174.579L119.725 170.139L123.336 172.036L121.347 176.071C120.766 177.453 120.389 178.908 119.9 180.327V180.308Z" fill="#EE1A64"/>
    <path d="M118.002 193.582L113.885 193.546L114.106 188.7C114.152 187.088 114.603 185.495 114.834 183.883L118.877 184.592C118.666 186.084 118.233 187.54 118.196 189.05L117.993 193.582H118.002Z" fill="#EE1A64"/>
    <path d="M119.338 207.105L115.285 207.805L114.419 203.079L114.051 198.262L118.159 198.031L118.5 202.582L119.338 207.105Z" fill="#EE1A64"/>
    <path d="M122.791 220.397L118.876 221.613C117.808 218.592 117.108 215.478 116.205 212.411L120.212 211.517C121.087 214.474 121.76 217.496 122.791 220.397Z" fill="#EE1A64"/>
    <path d="M128.135 233.073L124.45 234.841L122.378 230.475L121.346 228.283L120.508 226.026L124.358 224.635L125.16 226.809L126.155 228.9L128.135 233.073Z" fill="#EE1A64"/>
    <path d="M135.568 244.643L132.289 247.028L129.369 243.095C128.383 241.796 127.665 240.341 126.799 238.968L130.373 237.006C131.193 238.305 131.874 239.705 132.814 240.93L135.568 244.643Z" fill="#EE1A64"/>
    <path d="M145.205 254.444L142.589 257.493L138.83 254.232C137.577 253.145 136.564 251.855 135.422 250.667L138.517 248.069C139.595 249.174 140.544 250.4 141.713 251.413L145.214 254.444H145.205Z" fill="#EE1A64"/>
    <path d="M156.958 261.702L155.263 265.304C152.315 263.729 149.211 262.384 146.549 260.367L148.889 257.115C151.348 259.003 154.241 260.229 156.967 261.702H156.958Z" fill="#EE1A64"/>
    <path d="M170.351 265.488L169.697 269.394C168.002 269.293 166.39 268.749 164.741 268.399C163.102 268.003 161.443 267.681 159.887 267.018L161.25 263.287C162.687 263.913 164.235 264.199 165.755 264.567C167.284 264.89 168.776 265.406 170.351 265.488Z" fill="#EE1A64"/>
    <path d="M184.318 265.728L184.741 269.661L182.227 269.956C181.388 269.993 180.541 269.975 179.693 269.984C178.008 269.975 176.313 270.048 174.627 269.956L174.931 266.014C176.497 266.096 178.063 266.014 179.629 266.032C180.412 266.023 181.195 266.041 181.978 266.004L184.318 265.719V265.728Z" fill="#EE1A64"/>
    <path d="M198.023 262.743L199.35 266.483C196.245 267.708 192.892 268.206 189.65 269.026L188.895 265.148C191.944 264.365 195.085 263.922 198.032 262.753L198.023 262.743Z" fill="#EE1A64"/>
    <path d="M339.609 74.4114C339.609 80.6476 344.878 85.7048 351.373 85.7048C357.867 85.7048 363.136 80.6476 363.136 74.4114C363.136 68.1751 357.867 63.1179 351.373 63.1179C344.878 63.1179 339.609 68.1751 339.609 74.4114Z" fill="#FFBDD4"/>
    <path d="M345.484 74.4114C345.484 77.5341 348.119 80.0581 351.371 80.0581C354.622 80.0581 357.257 77.5249 357.257 74.4114C357.257 71.2979 354.622 68.7646 351.371 68.7646C348.119 68.7646 345.484 71.2979 345.484 74.4114Z" fill="#EE1A64"/>
    <path d="M228.914 236.357L206.008 263.997L210.363 267.606L233.269 239.966L228.914 236.357Z" fill="#FFBDD4"/>
    <path d="M204.628 241.254L202.08 246.304L234.648 262.734L237.196 257.684L204.628 241.254Z" fill="#FFBDD4"/>
    <path d="M370.975 157.74L363.376 149.099L356.375 157.74H370.975Z" fill="#FFBDD4"/>
    <path d="M370.975 162.355L363.376 153.714L356.375 162.355H370.975Z" fill="#FFBDD4"/>
    <path d="M370.975 167.992L363.376 159.352L356.375 167.992H370.975Z" fill="#FFBDD4"/>
    <path d="M361.369 169.844H366.168V167.173H361.369V169.844Z" fill="#FFBDD4"/>
    <path d="M351.907 148.731L344.307 140.09L337.307 148.731H351.907Z" fill="#FFBDD4"/>
    <path d="M351.907 153.346L344.307 144.705L337.307 153.346H351.907Z" fill="#FFBDD4"/>
    <path d="M351.907 158.983L344.307 150.343L337.307 158.983H351.907Z" fill="#FFBDD4"/>
    <path d="M342.308 160.835H347.107V158.164H342.308V160.835Z" fill="#FFBDD4"/>
    <path d="M335.704 161.94L328.104 153.3L321.104 161.94H335.704Z" fill="#FFBDD4"/>
    <path d="M335.704 166.555L328.104 157.915L321.104 166.555H335.704Z" fill="#FFBDD4"/>
    <path d="M335.704 172.193L328.104 163.552L321.104 172.193H335.704Z" fill="#FFBDD4"/>
    <path d="M326.105 174.044H330.904V171.373H326.105V174.044Z" fill="#FFBDD4"/>
    <path d="M339.608 106.707L332.009 98.0576L325.008 106.707H339.608Z" fill="#FFBDD4"/>
    <path d="M339.608 111.313L332.009 102.673L325.008 111.313H339.608Z" fill="#FFBDD4"/>
    <path d="M339.608 116.951L332.009 108.31L325.008 116.951H339.608Z" fill="#FFBDD4"/>
    <path d="M330.009 118.802H334.809V116.131H330.009V118.802Z" fill="#FFBDD4"/>
    <path d="M314.829 94.3453L307.229 85.7048L300.229 94.3453H314.829Z" fill="#FFBDD4"/>
    <path d="M314.829 98.9603L307.229 90.3198L300.229 98.9603H314.829Z" fill="#FFBDD4"/>
    <path d="M314.829 104.598L307.229 95.9573L300.229 104.598H314.829Z" fill="#FFBDD4"/>
    <path d="M305.222 106.449H310.021V103.778H305.222V106.449Z" fill="#FFBDD4"/>
    <path d="M312.526 215.091L304.927 206.442L297.926 215.091H312.526Z" fill="#FFBDD4"/>
    <path d="M312.526 219.697L304.927 211.057L297.926 219.697H312.526Z" fill="#FFBDD4"/>
    <path d="M312.526 225.335L304.927 216.694L297.926 225.335H312.526Z" fill="#FFBDD4"/>
    <path d="M302.927 227.186H307.727V224.515H302.927V227.186Z" fill="#FFBDD4"/>
    <path d="M295.807 229.655L288.208 221.015L281.207 229.655H295.807Z" fill="#FFBDD4"/>
    <path d="M295.807 234.27L288.208 225.62L281.207 234.27H295.807Z" fill="#FFBDD4"/>
    <path d="M295.807 239.908L288.208 231.267L281.207 239.908H295.807Z" fill="#FFBDD4"/>
    <path d="M286.218 241.759H291.018V239.088H286.218V241.759Z" fill="#FFBDD4"/>
    <path d="M320.502 240.414L314.589 233.69L309.145 240.414H320.502Z" fill="#FFBDD4"/>
    <path d="M320.502 243.997L314.589 237.273L309.145 243.997H320.502Z" fill="#FFBDD4"/>
    <path d="M320.502 248.382L314.589 241.658L309.145 248.382H320.502Z" fill="#FFBDD4"/>
    <path d="M313.031 249.819H316.762V247.747H313.031V249.819Z" fill="#FFBDD4"/>
    <path d="M93.0126 111.58L85.413 102.94L78.4121 111.58H93.0126Z" fill="#FFBDD4"/>
    <path d="M93.0126 116.186L85.413 107.546L78.4121 116.186H93.0126Z" fill="#FFBDD4"/>
    <path d="M93.0126 121.824L85.413 113.183L78.4121 121.824H93.0126Z" fill="#FFBDD4"/>
    <path d="M83.4058 123.685H88.2051V121.013H83.4058V123.685Z" fill="#FFBDD4"/>
    <path d="M68.2333 99.2274L60.6337 90.5869L53.6328 99.2274H68.2333Z" fill="#FFBDD4"/>
    <path d="M68.2333 103.833L60.6337 95.1926L53.6328 103.833H68.2333Z" fill="#FFBDD4"/>
    <path d="M68.2333 109.471L60.6337 100.83L53.6328 109.471H68.2333Z" fill="#FFBDD4"/>
    <path d="M58.6265 111.322H63.4258V108.651H58.6265V111.322Z" fill="#FFBDD4"/>
    <path d="M140.591 128.401L132.991 119.76L125.99 128.401H140.591Z" fill="#FFBDD4"/>
    <path d="M140.591 133.006L132.991 124.366L125.99 133.006H140.591Z" fill="#FFBDD4"/>
    <path d="M140.591 138.644L132.991 130.004L125.99 138.644H140.591Z" fill="#FFBDD4"/>
    <path d="M130.984 140.496H135.783V137.824H130.984V140.496Z" fill="#FFBDD4"/>
    <path d="M140.591 128.401L132.991 119.76L125.99 128.401H140.591Z" fill="#FFBDD4"/>
    <path d="M140.591 133.006L132.991 124.366L125.99 133.006H140.591Z" fill="#FFBDD4"/>
    <path d="M140.591 138.644L132.991 130.004L125.99 138.644H140.591Z" fill="#FFBDD4"/>
    <path d="M130.984 140.496H135.783V137.824H130.984V140.496Z" fill="#FFBDD4"/>
    <path d="M131.628 263.996L124.019 255.355L117.027 263.996H131.628Z" fill="#FFBDD4"/>
    <path d="M131.628 268.602L124.019 259.961L117.027 268.602H131.628Z" fill="#FFBDD4"/>
    <path d="M131.628 274.239L124.019 265.599L117.027 274.239H131.628Z" fill="#FFBDD4"/>
    <path d="M122.029 276.1H126.828V273.429H122.029V276.1Z" fill="#FFBDD4"/>
    <path d="M176.72 228.006L169.12 219.366L162.119 228.006H176.72Z" fill="#FFBDD4"/>
    <path d="M176.72 232.621L169.12 223.981L162.119 232.621H176.72Z" fill="#FFBDD4"/>
    <path d="M176.72 238.259L169.12 229.618L162.119 238.259H176.72Z" fill="#FFBDD4"/>
    <path d="M167.113 240.11H171.912V237.439H167.113V240.11Z" fill="#FFBDD4"/>
    <path d="M156.729 233.856L149.12 225.215L142.119 233.856H156.729Z" fill="#FFBDD4"/>
    <path d="M156.729 238.471L149.12 229.821L142.119 238.471H156.729Z" fill="#FFBDD4"/>
    <path d="M156.729 244.108L149.12 235.468L142.119 244.108H156.729Z" fill="#FFBDD4"/>
    <path d="M147.13 245.95H151.93V243.279H147.13V245.95Z" fill="#FFBDD4"/>
    <path d="M105.282 201.191L97.6825 192.551L90.6816 201.191H105.282Z" fill="#FFBDD4"/>
    <path d="M105.282 205.797L97.6825 197.156L90.6816 205.797H105.282Z" fill="#FFBDD4"/>
    <path d="M105.282 211.444L97.6825 202.794L90.6816 211.444H105.282Z" fill="#FFBDD4"/>
    <path d="M95.6832 213.295H100.482V210.624H95.6832V213.295Z" fill="#FFBDD4"/>
    <path opacity="0.1" d="M293.17 102.7C254.094 79.3856 203.513 92.153 180.199 131.238C160.909 163.562 166.317 203.752 190.875 229.876L186.665 236.932C184.252 240.976 185.587 246.254 189.631 248.659L194.025 251.284L182.17 271.144C178.54 270.122 174.515 271.605 172.498 274.985L138.368 332.18C135.955 336.224 137.291 341.503 141.335 343.907L152.997 350.862C157.04 353.275 162.319 351.939 164.723 347.895L198.852 290.7C200.869 287.32 200.261 283.073 197.636 280.365L209.492 260.505L213.885 263.13C217.929 265.544 223.208 264.208 225.612 260.164L229.822 253.108C264.476 262.31 302.409 247.986 321.698 215.663C345.013 176.587 332.246 126.006 293.161 102.691L293.17 102.7ZM306.315 206.497C288.067 237.08 248.484 247.074 217.902 228.826C187.319 210.578 177.324 170.995 195.573 140.413C213.821 109.83 253.403 99.8355 283.986 118.084C314.569 136.332 324.563 175.914 306.315 206.497Z" fill="black"/>
    <path d="M289.672 100.176C250.596 76.8617 200.015 89.629 176.701 128.714C153.386 167.79 166.153 218.371 205.238 241.685C244.314 265 294.895 252.224 318.21 213.148C341.524 174.072 328.748 123.491 289.672 100.176ZM214.413 226.302C183.83 208.054 173.836 168.471 192.084 137.889C210.332 107.306 249.915 97.3115 280.497 115.56C311.08 133.808 321.074 173.39 302.826 203.973C284.578 234.556 244.996 244.55 214.413 226.302Z" fill="#5D5FEF"/>
    <path d="M145.649 341.539C141.393 338.997 139.993 333.442 142.526 329.187L195.953 239.65C198.496 235.394 204.05 233.994 208.306 236.527C212.562 239.069 213.962 244.624 211.429 248.88L158.001 338.417C155.459 342.672 149.904 344.073 145.649 341.539Z" fill="#FFBDD4"/>
    <path d="M191.796 276.124L181.26 269.836C176.891 267.228 171.236 268.656 168.629 273.025L135.167 329.09C132.56 333.459 133.988 339.114 138.356 341.721L148.892 348.01C153.261 350.617 158.916 349.189 161.523 344.82L194.985 288.755C197.592 284.386 196.164 278.731 191.796 276.124Z" fill="#5D5FEF"/>
    <path d="M223.738 236.913L200.601 223.105C196.233 220.497 190.578 221.925 187.971 226.294L183.467 233.84C180.86 238.208 182.287 243.863 186.656 246.471L209.792 260.279C214.161 262.887 219.816 261.459 222.423 257.09L226.927 249.544C229.534 245.176 228.107 239.521 223.738 236.913Z" fill="#8C89EF"/>
    <path d="M366.544 34.1381L459.996 192.053C461.055 193.84 460.456 196.171 458.669 197.239L455.63 199.036C453.843 200.095 451.512 199.496 450.453 197.709L357.001 39.7941C355.941 38.007 356.54 35.6673 358.327 34.6079L361.367 32.8117C363.154 31.7523 365.485 32.3511 366.553 34.1381H366.544Z" fill="#5D5FEF"/>
    <path d="M367.666 28.4634L350.336 39.3723L363.276 59.9295L380.606 49.0206L367.666 28.4634Z" fill="#5D5FEF"/>
    <path d="M339.967 59.203L324.768 41.3232C324.768 41.3232 374.004 -16.3692 459.681 5.37023C459.681 5.37023 383.455 5.94136 339.958 59.203H339.967Z" fill="#F66194"/>
    <path d="M46.1882 43.7735C44.9262 43.7735 43.8945 42.7418 43.8945 41.4798V33.134C43.8945 31.872 44.9262 30.8403 46.1882 30.8403C47.4502 30.8403 48.4819 31.872 48.4819 33.134V41.4798C48.4819 42.7418 47.4502 43.7735 46.1882 43.7735Z" fill="#5D5FEF"/>
    <path d="M57.4342 39.2322H34.9302C33.9127 39.2322 33.0879 40.057 33.0879 41.0745V49.1255C33.0879 50.143 33.9127 50.9678 34.9302 50.9678H57.4342C58.4517 50.9678 59.2766 50.143 59.2766 49.1255V41.0745C59.2766 40.057 58.4517 39.2322 57.4342 39.2322Z" fill="#FFBDD4"/>
    <path d="M56.7372 26.686C56.7372 32.3235 52.1682 36.8925 46.5307 36.8925C40.8932 36.8925 36.3242 32.3235 36.3242 26.686C36.3242 21.0485 40.8932 16.4795 46.5307 16.4795C52.1682 16.4795 56.7372 21.0485 56.7372 26.686Z" fill="#FFBDD4"/>
    <path d="M53.944 26.6859C53.944 30.7851 50.6186 34.1012 46.5286 34.1012C42.4387 34.1012 39.1133 30.7851 39.1133 26.6859C39.1133 22.5867 42.4387 19.2705 46.5286 19.2705C50.6186 19.2705 53.944 22.5867 53.944 26.6859Z" fill="#5D5FEF"/>
    <g style={{ mixBlendMode: "overlay" }} opacity="0.4">
    <path d="M59.2785 43.7737H33.0898V54.8184H59.2785V43.7737Z" fill="black"/>
    </g>
    <path d="M45.9583 134.508C71.1463 134.508 91.5651 114.46 91.5651 89.7304C91.5651 65.0003 71.1463 44.9526 45.9583 44.9526C20.7704 44.9526 0.351562 65.0003 0.351562 89.7304C0.351562 114.46 20.7704 134.508 45.9583 134.508Z" fill="#5D5FEF"/>
    <path d="M83.2771 91.4351C84.2201 70.827 68.2785 53.3565 47.6705 52.4135C27.0625 51.4704 9.5919 67.4121 8.64888 88.0201C7.70587 108.628 23.6475 126.099 44.2555 127.042C64.8636 127.985 82.3341 112.043 83.2771 91.4351Z" fill="#5D5FEF"/>
    <path d="M45.9582 123.961C64.9598 123.961 80.3636 108.557 80.3636 89.5554C80.3636 70.5538 64.9598 55.1499 45.9582 55.1499C26.9566 55.1499 11.5527 70.5538 11.5527 89.5554C11.5527 108.557 26.9566 123.961 45.9582 123.961Z" fill="#FFBDD4"/>
    <path d="M46.5553 118.222C62.3874 118.222 75.2219 105.387 75.2219 89.5553C75.2219 73.7232 62.3874 60.8887 46.5553 60.8887C30.7231 60.8887 17.8887 73.7232 17.8887 89.5553C17.8887 105.387 30.7231 118.222 46.5553 118.222Z" fill="#FFBDD4"/>
    <path d="M46.5563 64.9511C45.9667 64.9511 45.4785 64.4721 45.4785 63.8734V57.8766C45.4785 57.287 45.9575 56.7988 46.5563 56.7988C47.1458 56.7988 47.634 57.2778 47.634 57.8766V63.8734C47.634 64.4629 47.155 64.9511 46.5563 64.9511Z" fill="#5D5FEF"/>
    <path d="M45.4332 120.507C44.8437 120.507 44.3555 120.028 44.3555 119.429V113.432C44.3555 112.842 44.8345 112.354 45.4332 112.354C46.0228 112.354 46.511 112.833 46.511 113.432V119.429C46.511 120.018 46.032 120.507 45.4332 120.507Z" fill="#5D5FEF"/>
    <path d="M70.2598 88.5974C70.2598 88.0079 70.7388 87.5196 71.3283 87.5196L77.3251 87.5012C77.9146 87.5012 78.4028 87.9802 78.4028 88.5698C78.4028 89.1593 77.9238 89.6475 77.3343 89.6475L71.3375 89.666C70.748 89.666 70.2598 89.187 70.2598 88.5974Z" fill="#5D5FEF"/>
    <path d="M14.7031 88.7539C14.7031 88.1643 15.1821 87.6761 15.7717 87.6761L21.7685 87.6577C22.358 87.6577 22.8462 88.1367 22.8462 88.7263C22.8462 89.3158 22.3672 89.804 21.7777 89.804L15.7809 89.8224C15.1913 89.8224 14.7031 89.3434 14.7031 88.7539Z" fill="#5D5FEF"/>
    <path d="M21.8984 88.2105L46.558 89.5554L46.5856 87.2617L21.8984 88.2105Z" fill="#5D5FEF"/>
    <path d="M70.9882 88.8461L46.3932 87.2617L46.2734 89.5554L70.9882 88.8461Z" fill="#5D5FEF"/>
    <path d="M27.9688 72.2468L45.7841 89.3528L47.2948 87.6303L27.9688 72.2468Z" fill="#F23E7C"/>
    <path d="M64.7595 104.745L47.1469 87.5012L45.5625 89.1593L64.7595 104.745Z" fill="#F23E7C"/>
    <path d="M28.623 105.455L47.1661 89.15L45.5817 87.4919L28.623 105.455Z" fill="#F23E7C"/>
    <path d="M64.1075 71.5374L45.4355 87.6301L46.9555 89.3526L64.1075 71.5374Z" fill="#F23E7C"/>
    <path d="M46.5298 64.0208L44.0059 88.6895H48.5932L46.5298 64.0208Z" fill="#5D5FEF"/>
    <path d="M45.581 113.11L48.5932 88.4962L44.0059 88.4041L45.581 113.11Z" fill="#5D5FEF"/>
    <path d="M473.156 305.145C478.878 279.051 462.364 253.258 436.27 247.535C410.176 241.813 384.384 258.327 378.661 284.421C372.938 310.515 389.452 336.307 415.546 342.03C441.64 347.753 467.433 331.239 473.156 305.145Z" fill="#E4E8FF"/>
    <path d="M425.353 328.983C443.737 328.707 458.416 313.58 458.14 295.196C457.864 276.812 442.737 262.133 424.353 262.409C405.97 262.685 391.29 277.812 391.566 296.196C391.842 314.58 406.969 329.259 425.353 328.983Z" fill="#FFCFE4"/>
    <path d="M469.14 298.236H447.198C444.453 298.236 442.205 295.988 442.205 293.243C442.205 290.498 444.453 288.25 447.198 288.25H469.14C471.885 288.25 474.133 290.498 474.133 293.243C474.133 295.988 471.885 298.236 469.14 298.236Z" fill="#FFCFE4"/>
    <path d="M452.518 296.032C452.723 281.31 440.956 269.209 426.234 269.004C411.513 268.798 399.412 280.566 399.206 295.287C399.001 310.009 410.768 322.11 425.49 322.315C440.211 322.521 452.312 310.753 452.518 296.032Z" fill="#233354"/>
  </svg>
</>
);

ErrorImage.propTypes = {
  className: PropTypes.string,
};

ErrorImage.defaultProps = {
  className: '',
}
