import React from "react";

import PropTypes from "prop-types";

export const LinkedInIcon = ({ className }) => (
  <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M17.848 18.3468H14.7463V13.4853C14.7463 12.3261 14.723 10.834 13.1285 10.834C11.5119 10.834 11.2651 12.0956 11.2651 13.4003V18.3468H8.16224V8.35028H11.1418V9.71201H11.1825C11.5992 8.92756 12.6117 8.09772 14.1248 8.09772C17.2672 8.09772 17.8492 10.1671 17.8492 12.8591V18.3468H17.848ZM4.65898 6.98273C4.4222 6.98304 4.18768 6.93661 3.96888 6.8461C3.75008 6.75559 3.55129 6.62279 3.38391 6.45531C3.21653 6.28782 3.08386 6.08895 2.99349 5.87008C2.90313 5.65122 2.85685 5.41668 2.85731 5.17989C2.85754 4.82355 2.96343 4.47529 3.16159 4.17913C3.35975 3.88298 3.64129 3.65223 3.97059 3.51608C4.29989 3.37993 4.66217 3.34448 5.01161 3.41423C5.36106 3.48397 5.68198 3.65577 5.93378 3.9079C6.18559 4.16003 6.35697 4.48117 6.42627 4.83071C6.49556 5.18024 6.45964 5.54248 6.32307 5.8716C6.18649 6.20073 5.95538 6.48196 5.65897 6.67974C5.36256 6.87752 5.01416 6.98296 4.65782 6.98273H4.65898ZM6.21392 18.3468H3.10288V8.35028H6.21508V18.3468H6.21392ZM19.4018 0.494141H1.54679C0.690176 0.494141 0 1.16919 0 2.00368V19.9343C0 20.7688 0.69134 21.4438 1.54562 21.4438H19.3971C20.2514 21.4438 20.9497 20.7688 20.9497 19.9343V2.00368C20.9497 1.16919 20.2514 0.494141 19.3971 0.494141H19.4006H19.4018Z" fill="#A5A5A5"/>
  </svg>
);

LinkedInIcon.propTypes = {
  className: PropTypes.string,
};

LinkedInIcon.defaultProps = {
  className: '',
};
