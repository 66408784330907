import React from "react";

export const XmartlabsTextLogoWhite = () => (
  <svg width="194" height="54" viewBox="0 0 194 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M43 0H0V38.1334L22.9333 14.7401H7.16667V7.2601H35.8333V36.7401H28.595V20.5334L5.73343 44H43V0Z" fill="white"/>
    <path d="M56.0001 18.0001L63.2425 7.67866L63.2189 11.1029L56.2604 1.00009H61.5384L65.9881 7.58152L63.7396 7.6058L68.1182 1.00009H73.1832L66.2247 10.9087V7.50866L73.5619 18.0001H68.1655L63.6449 11.0787H65.7987L61.3491 18.0001H56.0001Z" fill="white"/>
    <path d="M75.546 18.0001V1.00009H79.404L86.2677 12.5601H84.2323L90.9067 1.00009H94.7646L94.812 18.0001H90.5044L90.457 7.72723H91.1907L86.1967 16.3244H84.1139L78.9779 7.72723H79.8773V18.0001H75.546Z" fill="white"/>
    <path d="M97.0395 18.0001L104.353 1.00009H108.968L116.305 18.0001H111.43L105.702 3.35581H107.548L101.82 18.0001H97.0395ZM101.039 14.6972L102.246 11.1515H110.341L111.548 14.6972H101.039Z" fill="white"/>
    <path d="M118.534 18.0001V1.00009H126.107C127.622 1.00009 128.924 1.25104 130.013 1.75295C131.117 2.25485 131.969 2.98343 132.569 3.93866C133.168 4.87771 133.468 5.99485 133.468 7.29009C133.468 8.56914 133.168 9.67819 132.569 10.6172C131.969 11.5401 131.117 12.2525 130.013 12.7544C128.924 13.2401 127.622 13.4829 126.107 13.4829H121.137L123.22 11.4672V18.0001H118.534ZM128.782 18.0001L124.664 11.8072H129.658L133.8 18.0001H128.782ZM123.22 11.9772L121.137 9.76723H125.823C126.802 9.76723 127.528 9.54866 128.001 9.11152C128.49 8.67438 128.735 8.06723 128.735 7.29009C128.735 6.49676 128.49 5.88152 128.001 5.44438C127.528 5.00723 126.802 4.78866 125.823 4.78866H121.137L123.22 2.57866V11.9772Z" fill="white"/>
    <path d="M140.249 18.0001V4.81295H135.16V1.00009H150V4.81295H144.935V18.0001H140.249Z" fill="white"/>
    <path d="M56.0001 42.6732V26.327H60.7377V39.007H68.7295V42.6732H56.0001Z" fill="white"/>
    <path d="M69.6675 42.6732L77.0611 26.327H81.727L89.1445 42.6732H84.2154L78.425 28.5922H80.2913L74.5008 42.6732H69.6675ZM73.7112 39.4974L74.9315 36.088H83.1148L84.3351 39.4974H73.7112Z" fill="white"/>
    <path d="M91.3971 42.6732V26.327H99.9632C102.165 26.327 103.808 26.724 104.892 27.518C105.977 28.2964 106.519 29.3238 106.519 30.6004C106.519 31.4411 106.288 32.1805 105.825 32.8188C105.379 33.4415 104.741 33.9397 103.911 34.3133C103.098 34.6714 102.125 34.8504 100.992 34.8504L101.471 33.7295C102.651 33.7295 103.68 33.9085 104.557 34.2666C105.435 34.6091 106.113 35.115 106.591 35.7845C107.086 36.4383 107.333 37.24 107.333 38.1897C107.333 39.6063 106.743 40.7117 105.562 41.5056C104.398 42.284 102.691 42.6732 100.442 42.6732H91.3971ZM96.087 39.3339H100.059C100.872 39.3339 101.487 39.2016 101.901 38.9369C102.332 38.6567 102.547 38.2364 102.547 37.6759C102.547 37.1155 102.332 36.703 101.901 36.4383C101.487 36.1581 100.872 36.018 100.059 36.018H95.752V32.8188H99.3411C100.139 32.8188 100.737 32.6865 101.136 32.4218C101.534 32.1572 101.734 31.7602 101.734 31.2309C101.734 30.7016 101.534 30.3124 101.136 30.0633C100.737 29.7987 100.139 29.6663 99.3411 29.6663H96.087V39.3339Z" fill="white"/>
    <path d="M116.583 43.0001C115.211 43.0001 113.887 42.8367 112.611 42.5097C111.35 42.1672 110.321 41.7313 109.524 41.202L111.079 37.7927C111.829 38.2597 112.69 38.6489 113.663 38.9603C114.652 39.2561 115.633 39.404 116.606 39.404C117.26 39.404 117.787 39.3495 118.186 39.2405C118.584 39.116 118.872 38.9603 119.047 38.7735C119.238 38.5711 119.334 38.3376 119.334 38.0729C119.334 37.6993 119.159 37.4035 118.808 37.1856C118.457 36.9676 118.002 36.7886 117.444 36.6485C116.886 36.5084 116.263 36.3683 115.578 36.2281C114.908 36.088 114.23 35.909 113.544 35.6911C112.874 35.4731 112.26 35.1929 111.701 34.8504C111.143 34.4923 110.688 34.0331 110.337 33.4727C109.986 32.8966 109.811 32.1727 109.811 31.3009C109.811 30.3202 110.082 29.4328 110.625 28.6389C111.183 27.8449 112.012 27.2066 113.113 26.724C114.214 26.2414 115.586 26.0001 117.229 26.0001C118.329 26.0001 119.406 26.1247 120.459 26.3737C121.528 26.6073 122.477 26.9575 123.306 27.4246L121.847 30.8573C121.049 30.4369 120.259 30.1256 119.478 29.9232C118.696 29.7053 117.938 29.5963 117.205 29.5963C116.551 29.5963 116.024 29.6663 115.625 29.8064C115.227 29.931 114.939 30.1022 114.764 30.3202C114.589 30.5381 114.501 30.7872 114.501 31.0674C114.501 31.4255 114.668 31.7135 115.003 31.9314C115.354 32.1338 115.809 32.3051 116.367 32.4452C116.941 32.5697 117.564 32.702 118.234 32.8422C118.919 32.9823 119.597 33.1613 120.267 33.3792C120.953 33.5816 121.575 33.8618 122.134 34.2199C122.692 34.5624 123.139 35.0216 123.474 35.5977C123.825 36.1581 124 36.8664 124 37.7226C124 38.6723 123.721 39.5519 123.163 40.3614C122.62 41.1553 121.799 41.7936 120.698 42.2762C119.613 42.7588 118.241 43.0001 116.583 43.0001Z" fill="white"/>
  </svg>
);
