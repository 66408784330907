// extracted by mini-css-extract-plugin
export var blogIcons = "footer-module--blogIcons--57735";
export var container = "footer-module--container--c231e";
export var desktopLogo = "footer-module--desktopLogo--b1380";
export var filterContainer = "footer-module--filterContainer--c9557";
export var filterElement = "footer-module--filterElement--e64f6";
export var footerLink = "footer-module--footerLink--c2271";
export var footerMobileLogo = "footer-module--footerMobileLogo--baf27";
export var linkContainer = "footer-module--linkContainer--a40db";
export var logo = "footer-module--logo--e67c9";
export var optionContainer = "footer-module--optionContainer--cf080";
export var selectedLink = "footer-module--selectedLink--9d31e";