import React from "react";

import PropTypes from "prop-types";

export const Linkedin = ({ className }) => (
  <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M4.04554 2.11673C4.04529 2.61694 3.84634 3.09657 3.49246 3.45009C3.13858 3.80362 2.65876 4.00209 2.15854 4.00184C1.65833 4.00159 1.17871 3.80264 0.825182 3.44876C0.471656 3.09488 0.273188 2.61505 0.273438 2.11484C0.273688 1.61463 0.472636 1.13501 0.826516 0.781481C1.1804 0.427955 1.66022 0.229486 2.16043 0.229737C2.66064 0.229987 3.14027 0.428935 3.49379 0.782814C3.84732 1.13669 4.04579 1.61652 4.04554 2.11673V2.11673ZM4.10212 5.39845L0.330019 5.39845L0.330019 17.2051H4.10212L4.10212 5.39845ZM10.062 5.39845L6.3088 5.39845L6.3088 17.2051H10.0243L10.0243 11.0095C10.0243 7.55798 14.5225 7.23735 14.5225 11.0095L14.5225 17.2051H18.2475L18.2475 9.72694C18.2475 3.90848 11.5897 4.12537 10.0243 6.98274L10.062 5.39845V5.39845Z" fill="#9B9B9B"/>
  </svg>
);

Linkedin.propTypes = {
  className: PropTypes.string,
};

Linkedin.defaultProps = {
  className: '',
}
