// extracted by mini-css-extract-plugin
export var buttonIconPosition = "mobile-nav-menu-module--buttonIconPosition--972e2";
export var buttonIconStyles = "mobile-nav-menu-module--buttonIconStyles--3d611";
export var closeIconMobile = "mobile-nav-menu-module--closeIconMobile--60fb7";
export var container = "mobile-nav-menu-module--container--cc55a";
export var menuContainer = "mobile-nav-menu-module--menuContainer--dc463";
export var menuOptions = "mobile-nav-menu-module--menuOptions--59e55";
export var openButton = "mobile-nav-menu-module--openButton--e8b7f";
export var openMenu = "mobile-nav-menu-module--openMenu--22c6d";
export var partnerButton = "mobile-nav-menu-module--partnerButton--6e5e8";
export var partnerContainer = "mobile-nav-menu-module--partnerContainer--146cb";
export var socialContainer = "mobile-nav-menu-module--socialContainer--276f9";